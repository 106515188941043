import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import React, { ComponentType, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { useUser } from '../../hooks/accounts';
import { useTheme } from '../../hooks/accounts';
import { useEnsembleModelsQuery, useDefaultEnsembleModels } from '../../hooks/tags';
import { useTenants } from '../../hooks/tenants';
import { ErrorPage, VerifyUser } from '../../pages/not-found/error-page';
import { Loading } from '../loading/loading';

interface Props {
  component: ComponentType;
}

const AuthRoute: React.FC<Props> = ({ component }) => {
  const [theme] = useTheme();
  const { isAuthenticated, loginWithRedirect, user: authUser } = useAuth0();

  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  if (pathname === '/app/') {
    navigate('/app/redirect');
  }

  useEffect(() => {
    if (!isAuthenticated) {
      // Store the current route and query parameters in session storage
      localStorage.setItem('requestedRoute', JSON.stringify({ pathname, search }));

      loginWithRedirect();
    }
  }, [loginWithRedirect, isAuthenticated, pathname, search]);

  // Memoize to prevent HOC from rendering a "new" component when something at the top of the tree changes.
  const Component = useMemo(
    () =>
      withAuthenticationRequired(component, {
        onRedirecting: () => <Loading />,
      }),
    [component],
  );

  // bootstrap (blocking) tenants
  const { tenants, isError: tenantError } = useTenants();
  const { data: user, isError: userError } = useUser();
  useEnsembleModelsQuery();
  useDefaultEnsembleModels();

  if (authUser && authUser.email_verified === false) {
    return (
      <ThemeProvider theme={theme}>
        <ErrorPage>
          <VerifyUser />
        </ErrorPage>
      </ThemeProvider>
    );
  }

  if (tenantError || userError) throw new Error('Unable to bootstrap user / tenant data.');

  if (!tenants || !user) return <Loading />;

  return <Component />;
};

export default AuthRoute;
