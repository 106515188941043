import lazy from 'react-lazy-with-preload';

import MonitorComponentV2 from '../../app-v2/pages/monitor/monitor';

import MonitorComponent from './monitor/monitor';
import RedirectComponent from './redirect/redirect';

// Default
export const Redirect = RedirectComponent;
export const Monitor = MonitorComponent;
export const MonitorV2 = MonitorComponentV2;

// Lazy
export const Analyze = lazy(() => import('./analyze/analyze'));
export const AnalyzeV2 = lazy(() => import('../../app-v2/pages/analyze/analyze'));
export const LimitsV2 = lazy(() => import('./limits/limitsV2'));
export const Investigate = lazy(() => import('../../app-v2/pages/investigate/investigate'));
export const TimeSearch = lazy(() => import('./time-search/time-search'));
export const AlertPage = lazy(() => import('./alert/alert'));
export const LimitPage = lazy(() => import('./limits/limits'));
export const Share = lazy(() => import('./share/share'));
export const ShareV2 = lazy(() => import('../../app-v2/pages/share/share'));
export const AlertViewV2 = lazy(() => import('../../app-v2/pages/alertView/alertView'));
