import { createContext, useContext, useEffect, useState } from 'react';

import { useViewContext } from './view-context';

import { CheckboxStatus } from '@controlrooms/components';
import { InitialStateType, LabeledEventType, ViewType } from '@controlrooms/models';

export interface ViewOptionRecord {
  type_name: string;
  label_type_id: number;
  child_types?: ViewOptionRecord[];
}

// Define type for checkboxStatus
export interface CheckboxStatusType {
  status: CheckboxStatus;
}

export type CheckboxChange = {
  optionValue: number;
  subOptionValue?: number;
  subSubOptionValue?: number;
  selectChildren?: boolean;
};

interface FilterContextType {
  viewOptions: ViewOptionRecord[];
  checkboxStatus: InitialStateType;
  handleCheckboxChange?: (props: CheckboxChange) => void;
  changeCheckboxState: (props: CheckboxChange & { prev: InitialStateType }) => InitialStateType;
  labelTypesFilter: number[];
}

export const MonitorFilteringContext = createContext<FilterContextType>({
  viewOptions: [],
  checkboxStatus: {},
  labelTypesFilter: [],
  changeCheckboxState: () => ({}),
});

const setInitialCheckboxStatusForOption = (
  option: ViewOptionRecord,
  initialState: InitialStateType,
  setStatusTo: boolean,
) => {
  initialState[option.label_type_id] = setStatusTo;
  option.child_types?.forEach((child) =>
    setInitialCheckboxStatusForOption(child, initialState, setStatusTo),
  );
};

// Define initial state type

const createViewOptionRecord = (data: LabeledEventType[]): ViewOptionRecord[] => {
  if (!data || data.length === 0) {
    return [];
  }
  const viewOptions = data?.map((d) => {
    if (!d.type_name) {
      return {} as ViewOptionRecord;
    }
    const viewOption: ViewOptionRecord = {
      type_name: d.type_name || '',
      label_type_id: d.label_type_id as number,
      child_types: [],
    };
    if (d.child_types && d.child_types.length > 0) {
      viewOption.child_types = createViewOptionRecord(d.child_types);
    }
    return viewOption;
  });

  return viewOptions;
};

export const MonitorFilteringProvider = ({
  data,
  children,
}: {
  data: LabeledEventType[];
  children: React.ReactNode;
}) => {
  const { setViewState } = useViewContext();

  const viewOptions = createViewOptionRecord(data); // directly assign data from API to viewOptions

  const initialCheckboxStatus = viewOptions.reduce((prev, curr) => {
    if (curr.type_name.toLowerCase().indexOf('useful') === 0) {
      setInitialCheckboxStatusForOption(curr, prev, true);
    } else {
      setInitialCheckboxStatusForOption(curr, prev, false);
    }
    return prev;
  }, {} as InitialStateType);

  const [labelTypesFilter, setLabelTypesFilter] = useState<number[]>([]);
  const [checkboxStatus, setCheckboxStatus] = useState<InitialStateType>(initialCheckboxStatus);

  // Ensure that the initial state is set correctly when data is available
  // useEffect(() => {
  //   const storedLabelSelections = selectedLabelTypes;
  //   const storedLabelSelectionsLength = Object.keys(
  //     (storedLabelSelections as InitialStateType) || {},
  //   ).length;

  //   const thisStatus = storedLabelSelectionsLength
  //     ? (storedLabelSelections as InitialStateType)
  //     : initialCheckboxStatus;

  //   setCheckboxStatus(thisStatus);

  //   if (storedLabelSelectionsLength) {
  //     return;
  //   }

  //   setViewState((prevState) => {
  //     return {
  //       ...prevState,
  //       [selectedView]: {
  //         ...prevState[selectedView],
  //         view: {
  //           ...prevState[selectedView].view,
  //           [ViewType.MONITOR]: {
  //             ...prevState[selectedView].view[ViewType.MONITOR],
  //             selectedLabelTypes: thisStatus,
  //           },
  //         },
  //       },
  //     };
  //   });
  //   // eslint-disable-next-line
  // }, [viewState, selectedView]);

  const changeCheckboxState = ({
    optionValue,
    subOptionValue,
    subSubOptionValue,
    selectChildren = false,
    prev,
  }: CheckboxChange & { prev: InitialStateType }): InitialStateType => {
    const updatedStatuses: InitialStateType = { ...prev };

    let targetKey: string;

    if (subSubOptionValue && subOptionValue) {
      targetKey = `${subSubOptionValue}`;
    } else if (subOptionValue) {
      targetKey = `${subOptionValue}`;
    } else {
      targetKey = `${optionValue}`;
    }

    const targetOption = viewOptions.find((option) => option.label_type_id === parseInt(targetKey));

    const isCurrentStatusChecked = updatedStatuses[targetKey];
    updatedStatuses[targetKey] = !isCurrentStatusChecked;

    // If selectChildren is true, update the children as well
    if (selectChildren && targetOption?.child_types) {
      targetOption.child_types.forEach((subOption) => {
        updatedStatuses[subOption.label_type_id.toString()] = !isCurrentStatusChecked;
        if (subOption.child_types) {
          subOption.child_types.forEach((subSubOption) => {
            updatedStatuses[subSubOption.label_type_id.toString()] = !isCurrentStatusChecked;
          });
        }
      });
    }

    // Save the new result to sessionStorage
    setViewState((prevState) => {
      return {
        ...prevState,
        view: {
          ...prevState.view,
          [ViewType.MONITOR]: {
            ...prevState.view[ViewType.MONITOR],
            selectedLabelTypes: updatedStatuses,
          },
        },
      };
    });

    return updatedStatuses;
  };

  const handleCheckboxChange = ({
    optionValue,
    subOptionValue,
    subSubOptionValue,
    selectChildren = false,
  }: CheckboxChange) => {
    setCheckboxStatus((prev) =>
      changeCheckboxState({
        optionValue,
        subOptionValue,
        subSubOptionValue,
        selectChildren,
        prev,
      }),
    );
  };

  useEffect(() => {
    const checkedKeys = Object.keys(checkboxStatus).filter((key) => checkboxStatus[key]);
    setLabelTypesFilter(checkedKeys.map(Number));
  }, [checkboxStatus]);

  return (
    <MonitorFilteringContext.Provider
      value={{
        viewOptions,
        checkboxStatus,
        handleCheckboxChange,
        changeCheckboxState,
        labelTypesFilter,
      }}
    >
      {children}
    </MonitorFilteringContext.Provider>
  );
};

export const useMonitorFilteringContext = () => {
  return useContext(MonitorFilteringContext);
};
