import Request from './request';

import {
  EnvironmentResult,
  TenantConfig,
  TenantResult,
  PersistView,
  PersistCollection,
} from '@controlrooms/models';

const SERVICE_ROOT = '/accounts-v2';
const ACCOUNTS_CUSTOMIZE = '/user-customize/accounts-customize';

export const getTenants = (): Promise<TenantResult> =>
  Request.get(`${SERVICE_ROOT}/users/me/tenants`).then((r) => new TenantResult(r));

export const getTenantEnvironment = (): Promise<EnvironmentResult> =>
  Request.get(`${SERVICE_ROOT}/environments/me`).then((r) => new EnvironmentResult(r));

export const getView = (viewId: string): Promise<PersistView> =>
  Request.get(`${ACCOUNTS_CUSTOMIZE}/views/${viewId}`).then(({ result }) => result);

export const getViews = (): Promise<PersistView[]> =>
  Request.get(`${ACCOUNTS_CUSTOMIZE}/views`).then(({ result }) => result);

export const getCollections = (): Promise<PersistCollection[]> =>
  Request.get(`${ACCOUNTS_CUSTOMIZE}/views_collection`).then(({ result }) => result);

export const saveCollection = (collection: PersistCollection): Promise<PersistCollection> =>
  Request.post(`${ACCOUNTS_CUSTOMIZE}/views_collection`, collection).then(({ result }) => result);

export const updateCollection = (
  collectionId: string,
  collection: PersistCollection,
): Promise<string> =>
  Request.put(`${ACCOUNTS_CUSTOMIZE}/views_collection/${collectionId}`, collection).then(
    ({ result }) => result,
  );
export const deleteCollection = (collectionId: string): Promise<string> =>
  Request.delete(`${ACCOUNTS_CUSTOMIZE}/views_collection/${collectionId}`).then(
    ({ result }) => result,
  );

export const saveView = (view: PersistView): Promise<string> =>
  Request.post(`${ACCOUNTS_CUSTOMIZE}/views`, view).then(({ result }) => result);

export const deleteView = (viewId: string): Promise<string> =>
  Request.delete(`${ACCOUNTS_CUSTOMIZE}/views/${viewId}`).then(({ result }) => result);

export const updateView = (viewId: string, view: PersistView): Promise<string> =>
  Request.put(`${ACCOUNTS_CUSTOMIZE}/views/${viewId}`, view).then(({ result }) => result);

export const getTenantConfig = (): Promise<TenantConfig> =>
  Request.get(`${SERVICE_ROOT}/tenants/config`).then(({ result }) => new TenantConfig(result));
