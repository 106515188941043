import dayjs from 'dayjs';
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { LimitConfig, LimitMatchType, SubSystem, Tag } from '@controlrooms/models';

interface ContextProps {
  folder: SubSystem | undefined;
  tag: Tag | undefined;
  filterString: string;
  matchType: string;
  stateTags: Tag[];
  defaultLimit: LimitConfig;
  updateTagSelection: (folder: SubSystem, tag: Tag) => void;
  setEditableLimit: Dispatch<SetStateAction<LimitConfig | undefined>>;
  setFilterString: Dispatch<SetStateAction<string>>;
  setMatchType: Dispatch<SetStateAction<string>>;
  editableLimit: LimitConfig | undefined;
}

const dateFormat = 'YYYY-MM-DDTHH:mm:ss';
const defaultStartDate = dayjs().format(dateFormat);
const defaultEndDate = '2050-12-30T23:59:59';

const defaultLimit: LimitConfig = {
  limit_id: 0,
  tag_name: '',
  start_time: defaultStartDate,
  end_time: defaultEndDate,
  low_low_limit: null,
  low_limit: null,
  high_limit: null,
  high_high_limit: null,
  criticality: 'Low',
  center_type: 'Numeric',
  center_value: 0,
  limit_type: 'Numeric',
  is_active: true,
  states: [],
};

const defaultState = {
  setTag: () => null,
  tag: undefined,
  defaultLimit,
  stateTags: [],
  setStateTag: () => null,
  matchType: LimitMatchType.ALL,
  setMatchType: () => null,
  filterString: '',
  setFilterString: () => null,
  setFolder: () => null,
  folder: undefined,
  updateTagSelection: () => null,
  setEditableLimit: () => null,
  editableLimit: undefined,
};

export const LimitContext = createContext<ContextProps>(defaultState);

const LimitContextProvider: React.FC = ({ children }) => {
  const [tag, setTag] = useState<Tag | undefined>();
  const [stateTags, setStateTag] = useState<Tag[]>([]);
  const [filterString, setFilterString] = useState('');
  const [folder, setFolder] = useState<SubSystem>();
  const [matchType, setMatchType] = useState<string>(LimitMatchType.ALL);
  const [editableLimit, setEditableLimit] = useState<LimitConfig | undefined>();

  const updateTagSelection = useCallback((selectedFolder, selectedTag) => {
    setFolder(selectedFolder);
    setEditableLimit(undefined);
    selectedFolder.tags.forEach((tag: Tag) => {
      if (tag.name === selectedTag.name) {
        setTag(selectedTag);
      }
    });
    const stateTags = selectedFolder.tags.filter((tag: Tag) => tag.is_state);
    setStateTag(stateTags);
  }, []);

  const appState = useMemo(
    () => ({
      folder,
      tag,
      defaultLimit,
      stateTags,
      filterString,
      setFilterString,
      updateTagSelection,
      editableLimit,
      setEditableLimit,
      matchType,
      setMatchType,
    }),
    [editableLimit, filterString, folder, matchType, stateTags, tag, updateTagSelection],
  );

  return <LimitContext.Provider value={appState}>{children}</LimitContext.Provider>;
};

export default LimitContextProvider;
