import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

dayjs.extend(customParseFormat);

import { TimePresetMap, getTimePresetMapKey } from '../../../app/constants/time-selection-form';
import { TimeRangeUtil } from '../../../app/utils/time';
import { useViewContext } from '../../context/view-context';
import { TimeSelector } from '../timeframe-modal/time-selector';

import {
  Chevron,
  Container,
  DurationContainer,
  PrimaryLabel,
  SubLabel,
  TimeCard,
  TimeContainer,
  TimeRangeContainer,
  TimeZone,
} from './styles';

import { Modal, ModalContents, ModalOpenButton } from '@controlrooms/components';
import {
  DateFormats,
  Fields,
  TimePresetKeyType,
  TimePresetKeys,
  TimeRanges,
  TimeRangesType,
} from '@controlrooms/models';
import { TimeUtils, getQueryParams } from '@controlrooms/utils';

interface FormProps {
  timerange: TimeRangesType;
  preset: TimePresetKeyType | undefined;
  customTimeframe: string;
  timeframe: [dayjs.Dayjs, dayjs.Dayjs] | [];
  timezone: string;
}

export const TimeDisplayView: React.FC = () => {
  const { viewState, updateTimeSelection, recordTimelineHistory } = useViewContext();

  const { startTime, endTime, timezone, streamingTimeInSeconds, timeRange, nowSelected } =
    viewState.timeSelection;

  const [_startTime, _setStartTime] = useState(startTime);

  // Set the time selection from query params.
  const { startTime: qpStartTime, endTime: qpEndTime } = getQueryParams(location.search);

  useEffect(() => {
    if (qpStartTime && qpEndTime) {
      updateTimeSelection({
        startTime: dayjs(qpStartTime),
        endTime: dayjs(qpEndTime),
        timezone: timezone,
        timeRange: TimeRanges.CUSTOM,
        streamingTimeInSeconds: 0,
        // relative: false,
      });
    }
  }, [qpEndTime, qpStartTime, timezone, updateTimeSelection]);

  // Build a clock... basically
  useEffect(() => {
    if (streamingTimeInSeconds) {
      let timer: ReturnType<typeof setTimeout>;
      const tick = (waitMs: number) => {
        timer = setTimeout(() => {
          _setStartTime(dayjs().subtract(streamingTimeInSeconds, 'seconds'));
          tick(1000 - (Date.now() % 1000)); // set up the next tick
        }, waitMs);
      };
      tick(1000 - (Date.now() % 1000));

      const timeContextRefresh =
        streamingTimeInSeconds <= dayjs.duration(4, 'd').asSeconds() ? 30000 : 300000;
      const timeContextId = setInterval(() => {
        const now = dayjs();

        //Update the state
        updateTimeSelection({
          startTime: now.subtract(streamingTimeInSeconds as number, 'seconds'),
          endTime: now,
          timezone: timezone,
          timeRange: timeRange,
          streamingTimeInSeconds: streamingTimeInSeconds,
        });
      }, timeContextRefresh);
      return () => {
        timer && clearTimeout(timer);
        clearInterval(timeContextId);
      };
    }
  }, [streamingTimeInSeconds, startTime, timezone, timeRange, updateTimeSelection]);

  useEffect(() => {
    _setStartTime(startTime);
  }, [startTime]);

  const methods = useForm<FormProps>({
    mode: 'onChange',
    defaultValues: {
      [Fields.TIMERANGE]: timeRange,
      [Fields.PRESET]:
        getTimePresetMapKey(streamingTimeInSeconds) ?? TimePresetKeys.LAST_TWELVE_HOURS,
      [Fields.CUSTOM_TIMEFRAME]:
        timeRange === TimeRanges.CUSTOM
          ? `${TimeUtils.toTimezone(startTime, timezone).format(
              DateFormats.DATETIME_ALT,
            )} - ${TimeUtils.toTimezone(endTime, timezone).format(DateFormats.DATETIME_ALT)}`
          : '',
      [Fields.TIMEFRAME]:
        timeRange === TimeRanges.CUSTOM
          ? [TimeUtils.toTimezone(startTime, timezone), TimeUtils.toTimezone(endTime, timezone)]
          : [],
      [Fields.TIMEZONE]: timezone,
    },
  });

  const onConfirm = () => {
    const errors = methods.formState.errors;
    if (Object.values(errors).length) return false;

    // Reset view if the time is updated
    // navigate('.', { replace: true });

    if (methods.getValues(Fields.TIMERANGE as 'timerange') === TimeRanges.PRESET) {
      const _streamSeconds = TimePresetMap.get(
        methods.getValues(Fields.PRESET) as TimePresetKeyType,
      ) as number;
      const now = dayjs();
      recordTimelineHistory();
      updateTimeSelection(
        {
          startTime: now.subtract(_streamSeconds, 'seconds'),
          endTime: now,
          timezone: methods.getValues(Fields.TIMEZONE as 'timezone'),
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: _streamSeconds,
          // relative: true,
        },
        true,
      );

      return true;
    } else {
      const _timezone = methods.getValues(Fields.TIMEZONE as 'timezone');
      const _timeframe = methods.getValues(Fields.CUSTOM_TIMEFRAME as 'customTimeframe');

      if (!_timeframe || _timeframe.length === 0) {
        methods.setError(Fields.CUSTOM_TIMEFRAME as 'customTimeframe', { type: 'validate' });
        return false;
      }
      const _tokens = _timeframe.split(' - ');
      if (!_timeframe.match(/^[^a-zA-Z]+$/) || _tokens.length !== 2) {
        methods.setError(Fields.CUSTOM_TIMEFRAME as 'customTimeframe', { type: 'validate' });
        return false;
      }

      const _start = dayjs(_tokens[0], DateFormats.DATETIME_ALT);
      if (!_start.isValid()) {
        methods.setError(Fields.CUSTOM_TIMEFRAME as 'customTimeframe', {
          type: 'custom',
          message: 'Invalid start time',
        });
        return false;
      }

      const _end = dayjs(_tokens[1], DateFormats.DATETIME_ALT);
      if (!_end.isValid()) {
        methods.setError(Fields.CUSTOM_TIMEFRAME as 'customTimeframe', {
          type: 'custom',
          message: 'Invalid end time',
        });
        return false;
      }

      methods.clearErrors();
      recordTimelineHistory();
      updateTimeSelection(
        {
          startTime: _start.tz(_timezone, true),
          endTime: _end.tz(_timezone, true),
          timezone: _timezone,
          timeRange: TimeRanges.CUSTOM,
          streamingTimeInSeconds: 0,
          // relative: true,
        },
        true,
      );
      return true;
    }
  };

  const preparePrimaryLabel = () => {
    const hoursDisplay = TimeRangeUtil.calculateTimePreset('label', streamingTimeInSeconds);
    if (nowSelected && !hoursDisplay) {
      return (
        <>
          {TimeUtils.toTimezone(startTime, timezone).format(DateFormats.DATE)}{' '}
          <span className="time">
            {TimeUtils.toTimezone(startTime, timezone).format(DateFormats.TIME)}
          </span>{' '}
          - Now
        </>
      );
    }
    return hoursDisplay || TimeUtils.toTimezone(_startTime, timezone).format(DateFormats.TIME);
  };

  const isStreamingSpanningDays = () => {
    return dayjs()
      .subtract(streamingTimeInSeconds as number, 'seconds')
      .isBefore(dayjs().subtract(1, 'day').endOf('day'));
  };

  // change the calculation to account for daylight saving times in certain date selections
  const calculateForDST = () => {
    const startTimeUtcOffset = dayjs(startTime).utcOffset();
    const endTimeUtcOffset = dayjs(endTime).utcOffset();
    const dstAdjustment = (endTimeUtcOffset - startTimeUtcOffset) / 60;

    const totalHours = dayjs(endTime).diff(dayjs(startTime), 'hours') + dstAdjustment;
    return {
      seconds: Math.floor(dayjs(endTime).diff(dayjs(startTime), 's') % 60),
      minutes: Math.floor(dayjs(endTime).diff(dayjs(startTime), 'm') % 60),
      hours: totalHours % 24,
      days: Math.floor(totalHours / 24),
    };
  };

  const getTimeDiff = calculateForDST();

  const getDurationContent = () => {
    return (
      <>
        {getTimeDiff.days > 0 && `${getTimeDiff.days}d `}
        {getTimeDiff.hours > 0 && `${getTimeDiff.hours}h `}
        {getTimeDiff.minutes > 0 && `${getTimeDiff.minutes}m `}
        {dayjs(endTime).diff(dayjs(startTime), 'm') < 1 &&
          getTimeDiff.seconds > 0 &&
          `${getTimeDiff.seconds}s`}
      </>
    );
  };

  const getPrimaryLabelContent = () => {
    return (
      <>
        <span className="date">
          {dayjs(endTime).endOf('day').diff(dayjs().endOf('day')) === 0 &&
          dayjs(startTime).endOf('day').diff(dayjs().endOf('day')) === 0
            ? `Today`
            : TimeUtils.toTimezone(dayjs(startTime), timezone).format(DateFormats.DATE)}
        </span>
        &nbsp;
        {TimeUtils.toTimezone(dayjs(startTime), timezone).format(
          dayjs(endTime).diff(dayjs(startTime), 'm') >= 1
            ? DateFormats.TIME
            : DateFormats.TIME_SECONDS,
        )}
        &nbsp;-&nbsp;
        <span className="date">
          {dayjs(endTime).endOf('day').diff(dayjs(startTime).endOf('day')) > 0 &&
            TimeUtils.toTimezone(dayjs(endTime), timezone).format(DateFormats.DATE)}
        </span>
        &nbsp;
        {TimeUtils.toTimezone(dayjs(endTime), timezone).format(
          dayjs(endTime).diff(dayjs(startTime), 'm') >= 1
            ? DateFormats.TIME
            : DateFormats.TIME_SECONDS,
        )}
      </>
    );
  };

  const getSubLabelContent = () => {
    return (
      <>
        {streamingTimeInSeconds && !isStreamingSpanningDays() ? (
          `Today ${TimeUtils.toTimezone(startTime, timezone).format(
            DateFormats.TIME,
          )} - ${TimeUtils.toTimezone(endTime, timezone).format(DateFormats.TIME)}`
        ) : (
          <>
            {TimeUtils.toTimezone(startTime, timezone).format(DateFormats.DATE)}{' '}
            <span className="time">
              {TimeUtils.toTimezone(startTime, timezone).format(DateFormats.TIME)}
            </span>{' '}
            - {TimeUtils.toTimezone(endTime, timezone).format(DateFormats.DATE)}{' '}
            <span className="time">
              {TimeUtils.toTimezone(endTime, timezone).format(DateFormats.TIME)}
            </span>
          </>
        )}
      </>
    );
  };

  return (
    <FormProvider {...methods}>
      <Modal unmountOnExit>
        <ModalOpenButton>
          {streamingTimeInSeconds ? (
            <Container>
              <TimeCard data-testid="timecard_table">
                <TimeContainer data-testid="time-container">
                  <PrimaryLabel data-testid="timecard_date">{preparePrimaryLabel()}</PrimaryLabel>
                  <Chevron className="icon" />
                </TimeContainer>
                <TimeRangeContainer>
                  {nowSelected ? (
                    <DurationContainer>{getDurationContent()}</DurationContainer>
                  ) : (
                    <SubLabel data-testid="time-card-sub-label">{getSubLabelContent()}</SubLabel>
                  )}
                  <TimeZone
                    data-testid={`current-time-zone-${TimeUtils.zoneFormat(timezone, 'z')}`}
                  >
                    {TimeUtils.zoneFormat(timezone, 'z')}
                  </TimeZone>
                </TimeRangeContainer>
              </TimeCard>
            </Container>
          ) : (
            <Container>
              <TimeCard data-testid="timecard_table">
                <TimeContainer data-testid="time-container">
                  <PrimaryLabel data-testid="timecard_date">
                    {getPrimaryLabelContent()}
                  </PrimaryLabel>
                  <Chevron className="icon" />
                </TimeContainer>
                <TimeRangeContainer>
                  <DurationContainer data-testid="timecard_summary">
                    {getDurationContent()}
                  </DurationContainer>
                  <TimeZone>{TimeUtils.zoneFormat(timezone, 'z')}</TimeZone>
                </TimeRangeContainer>
              </TimeCard>
            </Container>
          )}
        </ModalOpenButton>
        <ModalContents
          title="Select Timeframe"
          confirmButtonCallback={onConfirm}
          closeButtonText="Cancel"
          styles={{ content: { maxWidth: '580px' } }}
        >
          <TimeSelector />
        </ModalContents>
      </Modal>
    </FormProvider>
  );
};

export const TimeDisplay = () => {
  const { viewState } = useViewContext();

  if (!viewState) return null;

  return <TimeDisplayView />;
};
