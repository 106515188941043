import React, { useContext, useEffect, useState } from 'react';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';

import { Paths } from '../../constants/paths';
import { AnalyzeContext } from '../../context/analyze-context';
import { AppContext } from '../../context/app-context';
import { windowWidthBreakpoints } from '../../global-styles';
import { SystemBrowser } from '../analyze-system-browser';
import { TimeDisplay } from '../time-display/time-display';
import { TimelineActions } from '../timeline-actions/timeline-actions';
import { Timeline } from '../timeline/timeline';

import {
  ActionsWrapper,
  DeskWrapper,
  MobileWrapper,
  Overlay,
  PopupContent,
  PopupHeader,
  PopupWrapper,
  StyledHeader,
  StyledHeaderActions,
  SystemsMenu,
  TimeWrapper,
  TimelineActionsWrapper,
} from './styles';

import { Button } from '@controlrooms/components';
import { ICONS, SUB_SYSTEMS_SEARCH_PARAM } from '@controlrooms/constants';

interface Props {
  children?: React.ReactNode;
}

export const ChartHeader: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const isAnalyze = matchPath(Paths.ANALYZE, pathname);
  const isTimeSearch = matchPath(Paths.TIME_SEARCH, pathname);
  const [show, setShow] = useState(false);
  const { setSelectedFolders, showAnalyzeLimits, setShowAnalyzeLimits } =
    useContext(AnalyzeContext);
  const { setShowLimits } = useContext(AppContext);
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const [search] = useSearchParams();
  const subSystemsParams = search.get(SUB_SYSTEMS_SEARCH_PARAM);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisibility(!isMenuVisible);
    showAnalyzeLimits ? setShowLimits(showAnalyzeLimits) : setShowLimits(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    showPopup();
  };

  useEffect(() => {
    if (!subSystemsParams) {
      return;
    }

    setSelectedFolders(subSystemsParams?.split(',').map(Number));
  }, [setSelectedFolders, subSystemsParams]);

  useEffect(() => {
    setShowAnalyzeLimits(true);
  }, [setShowAnalyzeLimits]);

  const showPopup = () => {
    return (
      <>
        <Overlay show={show} onClick={handleClose} />
        <PopupWrapper show={show}>
          <PopupHeader>
            <h4>Select systems</h4>
            <Button
              data-testid="close-icon-systems-mobile"
              iconName={ICONS.Close}
              buttonType="icon"
              buttonSize="small"
              onClick={handleClose}
              style={{ background: 'transparent', border: 'transparent' }}
            />
          </PopupHeader>
          <PopupContent>
            <SystemBrowser width={320} />
          </PopupContent>
        </PopupWrapper>
      </>
    );
  };

  return (
    <StyledHeader>
      {windowWidth > windowWidthBreakpoints.desk ? (
        <DeskWrapper>
          {!isTimeSearch && (
            <StyledHeaderActions>
              <div className="desk-view-action-wrap">
                <ActionsWrapper>
                  <div style={{ display: 'flex' }}>{children}</div>
                </ActionsWrapper>
              </div>
              <div className="desk-view">
                <TimeWrapper>
                  <TimeDisplay />
                </TimeWrapper>
              </div>
              <div className="desk-view-timeline-actions">
                <TimelineActionsWrapper>
                  <TimelineActions />
                </TimelineActionsWrapper>
              </div>
            </StyledHeaderActions>
          )}
          <Timeline />
        </DeskWrapper>
      ) : (
        <MobileWrapper>
          <div className="mobile-view">
            <div className="select-systems">
              {isAnalyze && (
                <SystemsMenu data-id="select-systems" onClick={handleShow}>
                  <Button
                    data-testid="search-icon-systems-mobile"
                    iconName={ICONS.Search}
                    buttonType="icon"
                    buttonSize="large"
                    style={{ background: 'transparent', border: 'transparent' }}
                  />
                </SystemsMenu>
              )}
              {isAnalyze && show && <div>{showPopup()}</div>}
            </div>
            <TimeWrapper>
              <TimeDisplay />
            </TimeWrapper>
            <div className="mobile-view-filter">
              <Button
                data-testid="filter-icon-mobile"
                iconName={ICONS.Filter}
                className={`${isMenuVisible ? 'filter-clicked' : ''}`}
                buttonType="icon"
                buttonSize="large"
                style={{ background: 'transparent', border: 'transparent' }}
                onClick={toggleMenu}
              />
            </div>
          </div>
          {isMenuVisible && (
            <div className="mobile-view-action-wrap">
              <ActionsWrapper>
                <div style={{ display: 'flex' }}>{children}</div>
              </ActionsWrapper>
            </div>
          )}
          {isMenuVisible && (
            <div className="mobile-view-timeline-actions">
              <TimelineActionsWrapper>
                <TimelineActions />
              </TimelineActionsWrapper>
            </div>
          )}
          <Timeline />
        </MobileWrapper>
      )}
    </StyledHeader>
  );
};
