import { IdToken, useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { matchPath } from 'react-router';
import { createSearchParams, generatePath, useNavigate, useSearchParams } from 'react-router-dom';

// import { Header } from '../../components/header/header';
import { useAnalytics } from '../../analytics';
import { DemoHeader } from '../../components/header/demo-header';
import { Header } from '../../components/header/header';
import { HeatmapGroup } from '../../components/heatmap/heatmap-group';
// import { Loading } from '../../components/loading/loading';
import { Loading } from '../../components/loading/loading';
import { MonitorHeader } from '../../components/monitor-header/monitor-header';
import { ReportsFilter } from '../../components/reports-filter/reports-filter';
import { Paths } from '../../constants/paths';
import { AppContext } from '../../context/app-context';
import { GlobalContext } from '../../context/global-context';
import MonitorChartContextProvider from '../../context/monitor-chart-context';
import MonitorContextProvider, { MonitorContext } from '../../context/monitor-context';
import { MonitorFilteringProvider } from '../../context/monitor-filtering-context';
import { useTenant } from '../../context/tenant-context';
import { useLabelsAPI } from '../../hooks/accounts-customize';
import {
  FolderSort,
  useFlatPlantFoldersWithModel,
  usePlantFoldersWithModel,
} from '../../hooks/folders';
import useHelpHero from '../../hooks/use-help-hero';
import { CR_USER_PROP, TokenUtil } from '../../utils/token';
import { checkOverflowUtil } from '../../utils/window';
import { Analyze } from '../routes';

import { GroupTooltipComponent } from './group-tooltip';
import { LabelTooltipComponent } from './label-tooltip';
import { MonitorContent, SidebarFooter, SidebarWrapper } from './styles';

import { Button, Container, Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

export const monitorCustomViewsEnabled = true;

const Monitor: React.FC = () => {
  const navigate = useNavigate();
  const { tenant } = useTenant();

  const {
    monitorTimeSelection: timeSelection,
    setAnalyzeTimeSelection,
    showLimits,
    setShowLimits,
  } = useContext(AppContext);
  const { setIsCustomViewFormVisible } = useContext(AppContext);
  const { track } = useAnalytics();

  const { selectedFolders, setSelectedFolders, showMonitorLimits } = useContext(MonitorContext);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const isReport = searchParams.get('report');

  const helpHero = useHelpHero();

  const { isLoading: isLoadingHeatmap, data: heatmapFolders } = usePlantFoldersWithModel(
    FolderSort.DEFAULT,
  );
  const { data: flattenedHeatmapFolders } = useFlatPlantFoldersWithModel(FolderSort.DEFAULT);

  const tenantId = matchPath({ path: '/demo/t/:tenantId/*' }, location.pathname);
  const isDemo = !!tenantId;

  const monitorContentEl = useRef<HTMLDivElement>(null);

  const sidebarWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDemo && import.meta.env.REACT_APP_HELP_HERO_TOUR_ID && heatmapFolders) {
      helpHero.startTour(import.meta.env.REACT_APP_HELP_HERO_TOUR_ID, { skipIfAlreadySeen: true });
    }
  }, [heatmapFolders, helpHero, isDemo]);

  if (helpHero) {
    helpHero.on((event, info) => {
      track(event.kind, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        tour_name: info.tour.name,
      });
      console.log('event, on monitor>>>', event.kind, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        tour_name: info.tour.name,
      });
    });
  }

  useEffect(() => {
    // Change GTA button style depending on window scrollable status
    if (!monitorContentEl.current) {
      return;
    }
    setIsScrollable(!checkOverflowUtil(monitorContentEl.current));

    window.onresize = () => {
      if (!monitorContentEl.current) {
        return;
      }
      setIsScrollable(!checkOverflowUtil(monitorContentEl.current));
    };
  }, [selectedFolders]);

  const handleCheckboxCheck = useCallback(
    (id: number, parentId: number) => {
      Analyze.preload();
      if (selectedFolders?.includes(id)) {
        // remove id of subfolder or parent folder id
        const newArr = selectedFolders.filter((x) => x !== id && x !== parentId);
        return setSelectedFolders([...new Set(newArr)]);
      }
      setSelectedFolders((arr: Array<number>) => [...new Set([id, ...arr])]);
    },
    [selectedFolders, setSelectedFolders],
  );

  const handleAnalyzeLink = useCallback(
    (systemId?: number) => {
      const folders = systemId
        ? [systemId]
        : selectedFolders?.length !== 0
        ? selectedFolders
        : [systemId];

      setAnalyzeTimeSelection(timeSelection);
      navigate(
        {
          pathname: generatePath(isDemo ? `${Paths.DEMO_ANALYZE}` : Paths.ANALYZE, {
            tenantId: tenant.toString(),
          }),
          search: `${createSearchParams({
            subsystems: folders.join(','),
          }).toString()}${isReport ? '&report=true' : ''}`,
        },
        { state: { folders: folders } },
      );
    },
    // eslint-disable-next-line
    [isDemo, isReport, navigate, selectedFolders, setAnalyzeTimeSelection, tenant, timeSelection],
  );

  const handleHeaderCheckboxCheck = useCallback(
    (id: number) => {
      const currentSubFolders = heatmapFolders?.subfolders?.find(
        (folder) => folder.folder === id,
      )?.subfolders;

      if (!selectedFolders?.includes(id)) {
        // add current folder id
        setSelectedFolders((arr: Array<number>) => [id, ...arr]);

        // add children sub folder id's
        currentSubFolders?.forEach((folder) => {
          if (!selectedFolders.includes(folder.folder)) {
            setSelectedFolders((arr: Array<number>) => [folder.folder, ...arr]);
          }
        });
      } else {
        // remove current id
        let newArr = selectedFolders.filter((x) => x !== id);

        // remove sub folders
        newArr = newArr?.filter((x) => !currentSubFolders?.find((folder) => folder.folder === x));
        setSelectedFolders([...newArr]);
      }
    },
    [heatmapFolders?.subfolders, selectedFolders, setSelectedFolders],
  );

  const closeFooter = () => {
    track('Monitor - Footer', {
      closeFooter: 'clicked',
      customViewModal: 'not visible',
      resetSelectedFolders: true,
    });
    setIsCustomViewFormVisible(false);
    setSelectedFolders([]);
  };

  return (
    <Container isPage>
      {isDemo ? <DemoHeader /> : isReport ? <ReportsFilter /> : <Header />}
      {!isLoadingHeatmap && <MonitorHeader />}
      {isLoadingHeatmap && <Loading />}
      <MonitorContent id="folder-heatmap-system" ref={monitorContentEl}>
        {isLoadingHeatmap ? (
          <></>
        ) : (
          <HeatmapGroup
            _folders={flattenedHeatmapFolders}
            selectedFolders={selectedFolders}
            handleAnalyzeLink={handleAnalyzeLink}
            handleHeaderCheckboxCheck={handleHeaderCheckboxCheck}
            handleCheckboxCheck={handleCheckboxCheck}
          />
        )}

        <SidebarWrapper ref={sidebarWrapper}>
          {selectedFolders.length !== 0 && (
            <SidebarFooter className={isScrollable ? 'isScrollable' : ''}>
              <div className="gta-wrapper">
                <button data-testid={'close-custom-view-bottom'} onClick={closeFooter}>
                  <Icon name={ICONS.Close} width="8" height="8" />
                </button>
                <div className="button-group">
                  {monitorCustomViewsEnabled && (
                    <Tooltip
                      label={
                        <>
                          Create a Custom View
                          <br />
                          with selected systems
                        </>
                      }
                    >
                      {!isDemo && (
                        <Button
                          iconName="plus"
                          buttonType="text"
                          buttonSize="small"
                          className="add-custom-view"
                          data-testid={'custom-view-button-bottom'}
                          onClick={() => {
                            track('Monitor - Open Custom View Form');
                            setIsCustomViewFormVisible(true);
                          }}
                        >
                          Custom View
                        </Button>
                      )}
                    </Tooltip>
                  )}
                  <Button
                    buttonType="primary"
                    buttonSize="small"
                    iconName={ICONS.AnalyzeLink}
                    className="link"
                    data-testid={'analyze-link'}
                    onMouseOver={() => Analyze.preload()}
                    onClick={() => {
                      track('Monitor - Go to Analyze via Button');
                      handleAnalyzeLink();
                      !showMonitorLimits
                        ? setShowLimits(showMonitorLimits)
                        : setShowLimits(!showLimits);
                    }}
                  >
                    Go to Analyze
                  </Button>
                </div>
              </div>
            </SidebarFooter>
          )}
        </SidebarWrapper>
      </MonitorContent>

      {/* Tooltips, absolute positions set in D3 code */}
      <GroupTooltipComponent />
      <LabelTooltipComponent />
    </Container>
  );
};

const MonitorWrapper: React.FC = () => {
  const { tenant } = useTenant();
  const { getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();
  const { demoAccessToken } = useContext(GlobalContext);

  // Fetch label types
  const { useLabelTypesQuery } = useLabelsAPI();
  const { data } = useLabelTypesQuery();

  const isDemo = !!matchPath({ path: '/demo/t/:tenantId/*' }, location.pathname);

  const [user, setUser] = useState<IdToken | undefined>();

  useEffect(() => {
    (async () => {
      if (isDemo) {
        const tokenExtracts = TokenUtil.extractUserProps(demoAccessToken);
        if (tokenExtracts && tokenExtracts.tenant) {
          navigate(
            generatePath(isDemo ? Paths.DEMO_MONITOR : Paths.MONITOR, {
              tenantId: String(tokenExtracts.tenant),
            }),
          );
          return;
        }
      }
      const user = await getIdTokenClaims();
      setUser(user);

      if (tenant === 0 && user && user[CR_USER_PROP].tenant !== tenant) {
        navigate(
          generatePath(isDemo ? Paths.DEMO_MONITOR : Paths.MONITOR, {
            tenantId: user[CR_USER_PROP].tenant,
          }),
        );
      }
    })();
  }, [isDemo, user, tenant, navigate, getIdTokenClaims, demoAccessToken]);

  return (
    <MonitorContextProvider>
      <MonitorFilteringProvider data={data?.result.label_types}>
        <MonitorChartContextProvider>
          <Monitor />
        </MonitorChartContextProvider>
      </MonitorFilteringProvider>
    </MonitorContextProvider>
  );
};

export default MonitorWrapper;
