import { query } from 'atomic-layout';
import styled from 'styled-components';

import { breakpoints } from '../../global-styles';

import { ButtonGroup, StyledIcon, OptionsGroup, Ul, StyledButton } from '@controlrooms/components';
import { colors, text } from '@controlrooms/design-tokens';

export const StyledHeader = styled.header`
  border-bottom: solid 1px #333;
  display: flex;
  height: 70px;
  position: relative;
  background: linear-gradient(360deg, #253d3f 0%, #121c1d 100%);
  box-shadow: ${colors.elevation.dark[2]};
  font-size: 19px;
  z-index: 2; //Necessary for box-shadow
  flex-shrink: 0;

  color: ${colors.k[100]};

  align-items: center;

  button,
  button:hover {
    background: none;
    border: none;
    box-shadow: none;
    color: ${colors.k[100]};
  }

  ${OptionsGroup} {
    background: none;
    padding: 0;
    margin: 0;
    button {
      padding: 0;
      margin: 0 1rem 0 0;
    }
  }

  .header-left {
    padding-left: 20px;
    flex: 1;
    align-items: center;
    display: flex;
    font-weight: 300;
    img {
      width: 30px;
    }
    nav {
      display: flex;
      a,
      .plant-name button {
        cursor: pointer;
        flex-shrink: 0;
        height: 40px;
        line-height: 40px;
        text-decoration: none;
        padding: 0 20px;
        color: ${colors.k[100]};
        &.active {
          color: ${({ theme }) => theme.navigation.active.textColor};
          font-weight: 700;
        }
        &.disabled {
          cursor: default;
        }
      }

      .plant-name {
        ${Ul} {
          top: 30px;
        }
        button {
          color: ${colors.focus.dark[2]};
          font-size: ${text.size['19-label']};
          width: auto;
          min-width: 100px;

          ${ButtonGroup} {
            align-items: center;
          }

          ${StyledIcon} {
            display: inline-block;
            width: 8px;
            height: 5px;
            vertical-align: middle;
            margin-left: 4px;
            path {
              fill: ${colors.content['neutral-3']};
            }
          }
        }
      }
    }
    .desk-view {
      display: none;
      ${breakpoints.desk} {
        display: flex;
      }
    }

    .plant-name {
      line-height: 40px;
    }
  }
  .mobile-view {
    display: flex;
    .user-name {
      text-transform: uppercase;
      margin-right: 10px;
      padding: 2px 8px 4px;
      background: ${colors.content['neutral-4']};
      border-radius: 16px;
      color: ${colors.content['neutral-1']};
    }
    a {
      svg {
        path {
          fill: ${colors.content['neutral-3']};
        }
      }
      &.active {
        svg {
          path {
            fill: ${colors.k[100]};
          }
        }
      }
    }
    ${breakpoints.desk} {
      display: none;
    }
  }
  .header-right {
    display: none;
    ${breakpoints.desk} {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .user-name {
        text-transform: uppercase;
        margin-right: 10px;
        padding: 2px 8px 4px;
        background: ${colors.content['neutral-4']};
        border-radius: 16px;
        color: ${colors.content['neutral-1']};
      }
      a {
        svg {
          path {
            fill: ${colors.content['neutral-3']};
          }
        }
        &.active {
          svg {
            path {
              fill: ${colors.k[100]};
            }
          }
        }
      }

      .export-select button {
        color: ${colors.content['neutral-3']};
      }

      .user-select ${Ul}, .export-select ${Ul} {
        right: 10px;

        button {
          width: 100%;
          text-align: left;
          color: inherit;
          height: inherit;
        }
      }

      .export-select ${Ul} {
        right: 80px;
      }
      a {
        padding-right: 20px;
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .user-dropdown button {
    font-size: 11px;
  }

  ${ButtonGroup} ${StyledButton} {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
  }
`;

export const StyledEllipse = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  overflow: hidden;
  width: 430px;
  height: 70px;
  z-index: -1;
  .ellipse {
    position: relative;
    width: 368px;
    height: 90px;
    right: -56px;
    top: -11px;

    background: linear-gradient(
      87.34deg,
      rgba(128, 247, 255, 0.28) 6.35%,
      rgba(255, 113, 113, 0.05) 91.93%
    );
    filter: blur(48px);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
`;

export const StyledBanner = styled.div`
  display: none;

  /* Todo: figure out why the line below doesn't work (Layout config) */
  /* @media ${query({ from: 'xxl' })} { */
  ${breakpoints.lg} {
    display: flex;
  }
  align-items: center;
  right: 100px;
  top: 0;
  overflow: hidden;
  height: 70px;
  text-align: right;

  .wrapper {
    display: flex;
  }

  .content {
    font-size: ${text.size['19-label']};
    font-weight: 300;
    min-width: 300px;
    text-align: center;
    margin-top: 10px;

    &.upload {
      text-align: right;
    }

    ${StyledButton} {
      font-size: ${text.size['13-prompt']};
      color: ${colors.focus.dark[1]};
      text-decoration: underline;
      display: inline-flex;
      align-items: center;

      span {
        font-weight: 400;
      }
    }

    ${StyledIcon} {
      margin-right: 5px;
      display: inline-block;
      path {
        fill: ${colors.focus.dark[1]};
      }
    }
  }
`;

export const NotificationWrapper = styled.div`
  a {
    display: flex;
    padding: 0 !important;
    margin: 0 1rem 0 0;
  }
  .active {
    color: ${({ theme }) => theme.navigation.active.textColor};
    font-weight: 700;
  }
`;

export const EnsembleErrorBanner = styled.div<{ type?: string }>`
  // height: 54px;
  width: 70%;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);

  display: flex;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--Data-Vis-Orange-1, #cdb25e);
  background: #fffaeb;
  color: var(--Content-Neutral-5, #464740);
  /* CR/13 Data */
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  > span {
    padding-right: 20px;
  }
  .icon,
  .close {
    padding-top: 4px;
  }
  .close {
    cursor: pointer;
  }
`;
