import React, { useCallback, useContext, useEffect } from 'react';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';

import { Paths } from '../../constants/paths';
import { AnalyzeChartContext } from '../../context/analyze-chart-context';
import { AnalyzeContext } from '../../context/analyze-context';
import { AppContext } from '../../context/app-context';
import { MonitorContext } from '../../context/monitor-context';

import { SeverityFilterContainer } from './styles';

import { Switch } from '@controlrooms/components';
import { ViewFiltersValue } from '@controlrooms/models';

interface Props {
  label?: string;
  showSlider?: boolean;
}

const LimitsToggle: React.FC<Props> = ({ label }) => {
  const { pathname } = useLocation();
  const { showMonitorLimits, setShowMonitorLimits } = useContext(MonitorContext);
  const { showAnalyzeLimits, setShowAnalyzeLimits } = useContext(AnalyzeContext);
  const { chartViewFilters } = useContext(AnalyzeChartContext);
  const { showLimits } = useContext(AppContext);
  const isMonitor =
    !!matchPath(Paths.MONITOR, pathname) || !!matchPath(Paths.DEMO_MONITOR, pathname);
  const START_TIME_PARAM = 'startTime';
  const END_TIME_PARAM = 'endTime';
  const [search] = useSearchParams();
  const startTimeParams = search.get(START_TIME_PARAM);
  const endTimeParams = search.get(END_TIME_PARAM);

  const toggleFiltersCheck = useCallback(
    (checked: boolean) => {
      chartViewFilters.map((filterValue) => {
        if (filterValue.value !== ViewFiltersValue.FREQUENT_VALUE) {
          filterValue.checked = checked;
        }
      });
    },
    [chartViewFilters],
  );

  useEffect(() => {
    !showLimits ? setShowAnalyzeLimits(showLimits) : '';
    if (startTimeParams || endTimeParams) {
      setShowAnalyzeLimits(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    toggleFiltersCheck(showAnalyzeLimits);
    // eslint-disable-next-line
  }, [showAnalyzeLimits]);

  return (
    <SeverityFilterContainer style={{ marginLeft: '1rem', gap: '0' }}>
      {label ? <span className="label">{label}</span> : <span className="label">Show Limits</span>}
      <div>
        <Switch
          selected={isMonitor ? showMonitorLimits : showAnalyzeLimits}
          onClick={(e, selected) => {
            isMonitor ? setShowMonitorLimits(selected) : setShowAnalyzeLimits(selected);
            toggleFiltersCheck(selected);
          }}
          dataTestId={'limits'}
        />
      </div>
    </SeverityFilterContainer>
  );
};

export default LimitsToggle;
