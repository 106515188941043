import dayjs from 'dayjs';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { MONITOR_MIN_ZOOM } from '../../app/components/timeline-actions/timeline-actions';
import { useTenant } from '../../app/context/tenant-context';
import { ChartInteractionUtil } from '../../app/utils/chart-interaction';
import { TimeRangeUtil } from '../../app/utils/time';

import { useViewContext } from './view-context';

import {
  GroupTooltipData,
  MonitorSavedView,
  PersistTimeSelection,
  SeverityFilter,
  TimeRanges,
  ViewType,
  Zoomable,
  ZoomTo,
} from '@controlrooms/models';

export interface MonitorContextProps {
  updateView: (view: MonitorSavedView) => void;
  selectedFolders: number[];
  // selectedViewTitle: string;
  setSelectedFolders: Dispatch<SetStateAction<number[]>>;
  groupTooltipData?: GroupTooltipData;
  setGroupTooltipData: Dispatch<SetStateAction<GroupTooltipData | undefined>>;
  labelTooltipData?: GroupTooltipData;
  setLabelTooltipData: Dispatch<SetStateAction<GroupTooltipData | undefined>>;
  panNext: () => void;
  panPrevious: () => void;
  zoomIn: Zoomable;
  zoomOut: () => void;
  jumpToNow: () => void;
  severityFilter: number;
  setSeverityFilter: Dispatch<SetStateAction<number>>;
  showMonitorLimits: boolean;
  setShowMonitorLimits: Dispatch<SetStateAction<boolean>>;
  // setSelectedViewTitle: Dispatch<SetStateAction<string>>;
}

const defaultState = {
  updateView: () => null,
  selectedFolders: [],
  // selectedViewTitle: 'All Systems',
  severityFilter: SeverityFilter.LOW,
  groupTooltipData: undefined,
  setGroupTooltipData: () => undefined,
  labelTooltipData: undefined,
  setLabelTooltipData: () => undefined,
  panNext: () => null,
  panPrevious: () => null,
  zoomIn: () => null,
  zoomOut: () => null,
  jumpToNow: () => null,
  showMonitorLimits: false,
  setSelectedFolders: () => null,
  setSeverityFilter: () => null,
  setShowMonitorLimits: () => null,
  // setSelectedViewTitle: () => null,
};

export const MonitorContext = createContext<MonitorContextProps>(defaultState);

const MonitorContextProvider: React.FC = ({ children }) => {
  // const location = useLocation();
  // const view = (location.state as MonitorRouteState)?.view;
  const { tenant } = useTenant();

  const { viewState, updateTimeSelection, resetTenantViewState, recordTimelineHistory } =
    useViewContext();

  const { timeSelection } = viewState;

  const { streamingTimeInSeconds } = timeSelection;

  const monitorViewState = viewState.view[ViewType.MONITOR];

  const [selectedFolders, setSelectedFolders] = useState<number[]>(
    monitorViewState.selectedFolders,
  );

  //Note: This is not needed in v2
  // const [selectedViewTitle, setSelectedViewTitle] = useState<string>(
  //   monitorSessionStorage?.selectedViewTitle,
  // );

  const [severityFilter, setSeverityFilter] = useState<number>(
    monitorViewState.severityFilter || SeverityFilter.LOW,
  );

  const [showMonitorLimits, setShowMonitorLimits] = useState<boolean>(
    monitorViewState.showLimits || defaultState.showMonitorLimits,
  );

  useEffect(() => {
    if (tenant === 0) {
      resetTenantViewState();
    }
  }, [tenant, resetTenantViewState]);

  // useEffect(() => {
  //   const monitorView: MonitorModeView = {
  //     ...monitorViewState,
  //     selectedFolders,
  //     severityFilter,
  //     showLimits: monitorViewState.showLimits,
  //   };
  //   setViewState((prevState) => ({
  //     ...prevState,
  //     [selectedView]: {
  //       ...prevState[selectedView],
  //       view: {
  //         ...prevState[selectedView].view,
  //         [ViewType.MONITOR]: monitorView,
  //       },
  //     },
  //   }));
  // },[monitorViewState, selectedFolders, selectedView, setViewState, severityFilter]);
  // useEffect(() => {
  //   setMonitorSessionStorage((prevState) => ({
  //     ...prevState,
  //     selectedFolders,
  //     severityFilter,
  //     showMonitorLimits,
  //     selectedViewTitle,
  //   }));
  //   // eslint-disable-next-line
  // }, [selectedFolders, severityFilter, showMonitorLimits, selectedViewTitle]);

  const [groupTooltipData, setGroupTooltipData] = useState<GroupTooltipData>();
  const [labelTooltipData, setLabelTooltipData] = useState<GroupTooltipData>();

  const updateView = useCallback(
    (view: MonitorSavedView) => {
      if (
        view.timeSelection.timeRange === TimeRanges.PRESET &&
        view.timeSelection.streamingTimeInSeconds
      ) {
        view.timeSelection.endTime = dayjs().toISOString();
        view.timeSelection.startTime = dayjs()
          .subtract(view.timeSelection.streamingTimeInSeconds, 'seconds')
          .toISOString();
      }
      updateTimeSelection(PersistTimeSelection.toTimeSelection(view.timeSelection));
      setSelectedFolders(view.selectedFolders);
      // setSelectedViewTitle(view.name);
      setSeverityFilter(view.severityFilter ? view.severityFilter : SeverityFilter.LOW);
      setShowMonitorLimits(view.showMonitorLimits || false);
    },
    [updateTimeSelection],
  );

  const zoomIn = useCallback(
    (params?: Partial<ZoomTo>) => {
      const [newStartTime, newEndTime] = ChartInteractionUtil.inCalculateBounds(
        timeSelection,
        MONITOR_MIN_ZOOM,
        params,
      );

      recordTimelineHistory();
      updateTimeSelection(
        {
          ...timeSelection,
          startTime: newStartTime,
          endTime: newEndTime,
          streamingTimeInSeconds: undefined,
          timeRange: TimeRanges.CUSTOM,
        },
        true,
      );
    },
    [timeSelection, updateTimeSelection, recordTimelineHistory],
  );

  const zoomOut = useCallback(() => {
    const [newStartTime, newEndTime] = ChartInteractionUtil.outCalculateBounds(timeSelection);
    recordTimelineHistory();
    updateTimeSelection(
      {
        ...timeSelection,
        startTime: newStartTime,
        endTime: newEndTime,
        streamingTimeInSeconds: undefined,
        timeRange: TimeRanges.CUSTOM,
      },
      true,
    );
  }, [timeSelection, updateTimeSelection, recordTimelineHistory]);

  const panPrevious = useCallback(() => {
    const [newStartTime, newEndTime] = ChartInteractionUtil.panLeftCalculateBounds(timeSelection);
    recordTimelineHistory();
    updateTimeSelection(
      {
        ...timeSelection,
        startTime: newStartTime,
        endTime: newEndTime,
        streamingTimeInSeconds: undefined,
        timeRange: TimeRanges.CUSTOM,
      },
      true,
    );
  }, [timeSelection, updateTimeSelection, recordTimelineHistory]);

  const panNext = useCallback(() => {
    // disable pan next if in relative mode
    if (streamingTimeInSeconds) return;

    // TODO: disabled for ux testable - needs more ticket detail
    // const newEndTime = dayjs(endTime).add(panStep, 's');

    const [newStartTime, newEndTime] = ChartInteractionUtil.panRightCalculateBounds(timeSelection);
    recordTimelineHistory();
    updateTimeSelection(
      {
        ...timeSelection,
        startTime: newStartTime,
        endTime: newEndTime,
        streamingTimeInSeconds: undefined,
        timeRange: TimeRanges.CUSTOM,
        // TODO: disabled for ux testable - needs more ticket detail
        // endTime: dayjs().diff(newEndTime, 'seconds') <= 0 ? dayjs() : newEndTime,
      },
      true,
    );
  }, [streamingTimeInSeconds, timeSelection, updateTimeSelection, recordTimelineHistory]);

  const jumpToNow = useCallback(() => {
    // disable jump to now if in relative mode
    if (streamingTimeInSeconds) return;

    //TODO: Need to handle for batch env in v2 - needs more ticket detail - commenting the logic now
    const now = dayjs();
    const diff = now.diff(timeSelection.endTime);
    const newStartTime = timeSelection.startTime.add(diff);
    const streamingSeconds = now.diff(newStartTime, 'seconds');
    recordTimelineHistory();
    updateTimeSelection(
      {
        ...timeSelection,
        endTime: now,
        startTime: newStartTime,
        nowSelected: true,
        streamingTimeInSeconds: streamingSeconds,
        timeRange: TimeRangeUtil.calculateTimePreset('value', streamingSeconds)
          ? TimeRanges.PRESET
          : TimeRanges.CUSTOM,
      },
      true,
    );
  }, [streamingTimeInSeconds, timeSelection, updateTimeSelection, recordTimelineHistory]);

  const monitorState = useMemo(
    () => ({
      updateView,
      selectedFolders,
      setSelectedFolders,
      groupTooltipData,
      setGroupTooltipData,
      labelTooltipData,
      setLabelTooltipData,
      panNext,
      panPrevious,
      zoomIn,
      zoomOut,
      severityFilter,
      setSeverityFilter,
      jumpToNow,
      showMonitorLimits,
      setShowMonitorLimits,
    }),
    [
      panNext,
      panPrevious,
      selectedFolders,
      groupTooltipData,
      labelTooltipData,
      updateView,
      zoomIn,
      zoomOut,
      severityFilter,
      jumpToNow,
      showMonitorLimits,
    ],
  );

  return <MonitorContext.Provider value={monitorState}>{children}</MonitorContext.Provider>;
};

export default MonitorContextProvider;
