import { IdToken, useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

// import { Header } from '../../components/header/header';
import { useViewContext } from '../../../app-v2/context/view-context';
import { useAnalytics } from '../../../app/analytics';
import { Paths } from '../../../app/constants/paths';
import { useTenant } from '../../../app/context/tenant-context';
import { useLabelsAPI } from '../../../app/hooks/accounts-customize';
import {
  FolderSort,
  useFlatPlantFoldersWithModel,
  usePlantFoldersWithModel,
} from '../../../app/hooks/folders';
import useHelpHero from '../../../app/hooks/use-help-hero';
import { AnalyzeV2 } from '../../../app/pages/routes';
import { CR_USER_PROP } from '../../../app/utils/token';
import { HeatmapGroup } from '../../components/heatmap/heatmap-group';
// import { Loading } from '../../components/loading/loading';
import { usePanelContext } from '../../components/layout/Panel';
import { Loading } from '../../components/loading/loading';
import { MonitorHeader } from '../../components/monitor-header/monitor-header';
import { useLayoutContext } from '../../context/layout-context';
import MonitorChartContextProvider from '../../context/monitor-chart-context';
import MonitorContextProvider, { MonitorContext } from '../../context/monitor-context';
import { MonitorFilteringProvider } from '../../context/monitor-filtering-context';

import { GroupTooltipComponent } from './group-tooltip';
import { LabelTooltipComponent } from './label-tooltip';
import { MonitorContent } from './styles';

import { Container } from '@controlrooms/components';
import { ViewType } from '@controlrooms/models';

export const monitorCustomViewsEnabled = true;

const Monitor: React.FC = () => {
  const { activeModes, activeView, setActiveModes } = useLayoutContext();
  const { viewId, setViewState, setSearchMode, clearTrendSearchData } = useViewContext();
  const { sidebarWidth } = usePanelContext();
  const { track } = useAnalytics();

  const { selectedFolders, setSelectedFolders } = useContext(MonitorContext);

  const helpHero = useHelpHero();

  const { isLoading: isLoadingHeatmap, data: heatmapFolders } = usePlantFoldersWithModel(
    FolderSort.DEFAULT,
  );

  const { data: flattenedHeatmapFolders } = useFlatPlantFoldersWithModel(FolderSort.DEFAULT);

  const monitorContentEl = useRef<HTMLDivElement>(null);

  if (helpHero) {
    helpHero.on((event, info) => {
      track(event.kind, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        tour_name: info.tour.name,
      });
    });
  }

  const handleViewChange = useCallback(
    (view: ViewType, folders?: number[]) => {
      setViewState((prev) => {
        const currentView = prev;
        const selectedMode = activeModes[activeView];
        currentView.view[ViewType.MONITOR].selectedFolders =
          folders || currentView.view[selectedMode].selectedFolders;
        currentView.view[ViewType.ANALYZE].selectedFolders =
          folders || currentView.view[selectedMode].selectedFolders;
        currentView.view[ViewType.ANALYZE].showLimits =
          currentView.view[ViewType.MONITOR].showLimits;
        return {
          ...prev,
          currentView,
        };
      });
      setActiveModes((prev) => {
        return {
          ...prev,
          [viewId]: view,
        };
      });
    },
    [activeModes, activeView, setActiveModes, setViewState, viewId],
  );

  const handleCheckboxCheck = useCallback(
    (id: number, parentId: number) => {
      AnalyzeV2.preload();
      if (selectedFolders?.includes(id)) {
        // remove id of subfolder or parent folder id
        const newArr = selectedFolders.filter((x) => x !== id && x !== parentId);
        return setSelectedFolders([...new Set(newArr)]);
      }
      setSelectedFolders((arr: Array<number>) => [...new Set([id, ...arr])]);
    },
    [selectedFolders, setSelectedFolders],
  );

  const handleAnalyzeLink = useCallback(
    (systemId?: number) => {
      setSearchMode(false);
      clearTrendSearchData();
      const selectedSystemId = systemId ? [systemId] : [];
      const selectedFolderIds = selectedFolders?.length > 0 ? selectedFolders : [];
      const folders = [...selectedSystemId, ...selectedFolderIds];

      // updateTimeSelection(timeSelection, true);

      handleViewChange(ViewType.ANALYZE, folders as number[]);
      return;
    },
    [handleViewChange, selectedFolders, setSearchMode, clearTrendSearchData],
  );

  const handleHeaderCheckboxCheck = useCallback(
    (id: number) => {
      const currentSubFolders = heatmapFolders?.subfolders?.find(
        (folder) => folder.folder === id,
      )?.subfolders;

      if (!selectedFolders?.includes(id)) {
        // add current folder id
        setSelectedFolders((arr: Array<number>) => [id, ...arr]);

        // add children sub folder id's
        currentSubFolders?.forEach((folder) => {
          if (!selectedFolders.includes(folder.folder)) {
            setSelectedFolders((arr: Array<number>) => [folder.folder, ...arr]);
          }
        });
      } else {
        // remove current id
        let newArr = selectedFolders.filter((x) => x !== id);

        // remove sub folders
        newArr = newArr?.filter((x) => !currentSubFolders?.find((folder) => folder.folder === x));
        setSelectedFolders([...newArr]);
      }
    },
    [heatmapFolders?.subfolders, selectedFolders, setSelectedFolders],
  );

  return (
    <Container isPage>
      {!isLoadingHeatmap && <MonitorHeader />}
      {isLoadingHeatmap && <Loading />}
      <MonitorContent id={`folder-heatmap-system-${viewId}`} ref={monitorContentEl}>
        {isLoadingHeatmap ? (
          <></>
        ) : (
          <HeatmapGroup
            _folders={flattenedHeatmapFolders}
            selectedFolders={selectedFolders}
            handleAnalyzeLink={handleAnalyzeLink}
            handleHeaderCheckboxCheck={handleHeaderCheckboxCheck}
            handleCheckboxCheck={handleCheckboxCheck}
            width={sidebarWidth}
          />
        )}
      </MonitorContent>

      {/* Tooltips, absolute positions set in D3 code */}
      <GroupTooltipComponent />
      <LabelTooltipComponent />
    </Container>
  );
};

const MonitorWrapper: React.FC = () => {
  const { tenant } = useTenant();
  const { getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();

  // Fetch label types
  const { useLabelTypesQuery } = useLabelsAPI();
  const { data } = useLabelTypesQuery();

  const [, setUser] = useState<IdToken | undefined>();

  useEffect(() => {
    (async () => {
      const user = await getIdTokenClaims();
      setUser(user);

      if (tenant === 0 && user && user[CR_USER_PROP].tenant !== tenant) {
        navigate(
          generatePath(Paths.VIEWS, {
            tenantId: user[CR_USER_PROP].tenant,
          }),
        );
      }
    })();
  }, [getIdTokenClaims, navigate, tenant]);

  return (
    <MonitorContextProvider>
      <MonitorFilteringProvider data={data?.result.label_types}>
        <MonitorChartContextProvider>
          <Monitor />
        </MonitorChartContextProvider>
      </MonitorFilteringProvider>
    </MonitorContextProvider>
  );
};

export default MonitorWrapper;
