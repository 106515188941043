import dayjs, { Dayjs } from 'dayjs';

import { getTimePresetMapKey, PresetOptions, SelectOption } from '../constants/time-selection-form';

import { ApiTimeServicePayload } from '@controlrooms/models';

export class TimeRangeUtil {
  public static ofDuration(seconds: number): ApiTimeServicePayload {
    const roundedNow = dayjs().add(60, 'seconds').set('second', 0).set('milliseconds', 0);

    return {
      start_time: roundedNow.subtract(seconds, 'seconds').toISOString(),
      end_time: roundedNow.toISOString(),
    };
  }

  public static ofDurationV2(seconds: number, isStartTime?: boolean): string {
    const roundedNow = dayjs().add(60, 'seconds').set('second', 0).set('milliseconds', 0);

    return isStartTime
      ? roundedNow.subtract(seconds, 'seconds').toISOString()
      : roundedNow.toISOString();
  }

  public static calculateTimePreset(key: 'value' | 'label', streamingSeconds?: number) {
    let value = null;
    PresetOptions.map((option: SelectOption, index) => {
      if (getTimePresetMapKey(streamingSeconds) === option.value) value = PresetOptions[index][key];
    });
    return value;
  }
}

export function differenceInMinutes(startDate: Dayjs, endDate: Dayjs) {
  const start = dayjs(startDate),
    end = dayjs(endDate);
  return end.diff(start, 'minute');
}
