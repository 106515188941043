import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useTenant } from '../../../app/context/tenant-context';
import { getAppRouteParams } from '../../../app/utils/app';
import { useAppContext } from '../../context/app-context';
import { useSwitchTenants, useTenants } from '../../hooks/tenants';

import { ButtonGroup, Icon, Li, OptionsGroup as OG, Ul } from '@controlrooms/components';
import { useClickOutside } from '@controlrooms/hooks';

const OptionsGroup = styled(OG)`
  padding: 0px;
  margin: 0px;
  width: 100%;
`;
const PlantName = styled.div`
  width: 100%;
  button {
    cursor: pointer;
    background: transparent;
    border: 0px;
  }
`;
export const HeaderWrapper = styled.div`
  background: ${({ theme }) => theme.v2.header.tenantHeaderBg};
  width: 100%;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TenantSelect = styled.div`
  gap: 5px;
  display: flex;
  align-items: center;
  overflow: hidden;
  color: ${({ theme }) => theme.v2.header.content.color};
  text-overflow: ellipsis;
  font-family: 'Space Grotesk';
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  span {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }
  svg {
    fill: ${({ theme }) => theme.v2.header.content.color};
  }
`;

export const TenantSelector: React.FC = () => {
  const { tenants } = useTenants();
  const { tenant } = useTenant();
  const { switchTenant } = useSwitchTenants();
  const { isTenantSwitching } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const plantUlRef = useRef(null);

  const tenantIdFromUrl = getAppRouteParams(location.pathname)?.params?.tenantId || '0';

  useEffect(() => {
    if (tenantIdFromUrl && Number(tenantIdFromUrl) !== tenant) {
      const hasAccess = tenants?.some((t) => t.id.toString() === tenantIdFromUrl);
      if (!hasAccess) {
        navigate('/404'); // Redirect to 404 if no access
      }
    }
  }, [tenantIdFromUrl, tenant, tenants, navigate]);

  const handleTenantChange = async (tenantId: number) => {
    const hasAccess = tenants?.some((t) => t.id === tenantId);
    setIsOpen(false);
    if (hasAccess) {
      await switchTenant(tenantId);
    } else {
      setIsOpen(false);
    }
  };

  useClickOutside(plantUlRef, () => setIsOpen(false));

  return (
    <HeaderWrapper style={{ height: '50px' }}>
      <PlantName>
        <OptionsGroup>
          <button
            disabled={isTenantSwitching}
            onClick={() => {
              setIsOpen((prevState) => !prevState);
            }}
          >
            <ButtonGroup>
              <TenantSelect>
                <span>{tenants?.find((t) => t.id === parseInt(tenantIdFromUrl, 10))?.name}</span>
                <span>
                  {tenants?.length && <Icon name="chevron" style={{ display: 'flex' }} />}
                </span>
              </TenantSelect>
            </ButtonGroup>
          </button>
          <Ul isOpen={isOpen} ref={plantUlRef}>
            {tenants?.map((t) => (
              <Li
                data-testid={`plant-button-${t.name}`}
                onClick={() => handleTenantChange(t.id)}
                key={t.id}
                value={t.id}
              >
                {t.name}
              </Li>
            ))}
          </Ul>
        </OptionsGroup>
      </PlantName>
    </HeaderWrapper>
  );
};
